var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components_common_thumb_task_page" }, [
    1
      ? _c(
          "div",
          {
            staticClass: "new_content_group",
            class: {
              isimportant: _vm.item.isimportant && 0,
              isdo: _vm.item.isdo && !_vm.item.isimportant && 0,
              both: _vm.item.isimportant && _vm.item.isdo && 0,
            },
            on: { click: _vm.openTaskBox },
          },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open_select,
                  expression: "open_select",
                },
              ],
              staticClass: "select_group cp",
              class: { active: _vm.item.cf_selected },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchSelect()
                },
              },
            }),
            _vm.item.colour
              ? _c(
                  "div",
                  { staticClass: "color_group" },
                  [
                    _vm._l(_vm.getColorList(_vm.item), function (item, index) {
                      return [
                        _c("div", {
                          key: index,
                          staticClass: "color",
                          style: { "border-left-color": item },
                        }),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.dot,
                  expression: "item.dot",
                },
              ],
              staticClass: "dot",
            }),
            _c("div", { staticClass: "info_group" }, [
              _c(
                "div",
                { staticClass: "info_box" },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "info cp un_sel" },
                      [
                        _c("title-status-tag", {
                          staticClass: "status_tag_group",
                          attrs: {
                            "level-status": String(_vm.item.levelStatus),
                            "process-status": String(_vm.item.processStatus),
                            size: "14px",
                          },
                        }),
                        _c("span", { staticClass: "task_name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.item.taskDetail ||
                                  _vm.item.name ||
                                  _vm.item.taskName
                              ) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  _vm.menu
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-end",
                            trigger: "click",
                            "popper-class":
                              "components_common_thumb_task_page_popover",
                            "visible-arrow": false,
                          },
                          on: { show: _vm.handleShowMenu },
                          model: {
                            value: _vm.switch_more_btn,
                            callback: function ($$v) {
                              _vm.switch_more_btn = $$v
                            },
                            expression: "switch_more_btn",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "more_list" },
                            [
                              _vm.canSetStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "list_group un_sel cp",
                                      on: {
                                        click: function ($event) {
                                          _vm.switch_more_btn = false
                                          _vm.touchSetStatusTag()
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                设置状态\n              "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.permissionOfMigrateTask
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "list_group un_sel cp",
                                      on: {
                                        click: function ($event) {
                                          _vm.switch_more_btn = false
                                          _vm.touchMigrateTask()
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                任务迁移\n              "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.canCall
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "list_group un_sel cp",
                                      on: {
                                        click: function ($event) {
                                          _vm.switch_more_btn = false
                                          _vm.touchCall()
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                调用\n              "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.recycle
                                ? [
                                    _vm.canSetLabel
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "list_group un_sel cp",
                                            on: {
                                              click: function ($event) {
                                                _vm.switch_more_btn = false
                                                _vm.openTagBox()
                                              },
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "text" }, [
                                              _vm._v("设置标签"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.canShare
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "list_group un_sel cp",
                                            on: {
                                              click: function ($event) {
                                                _vm.switch_more_btn = false
                                                _vm.doShare()
                                              },
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "text" }, [
                                              _vm._v("分享任务"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.canCollect
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "list_group un_sel cp",
                                            on: {
                                              click: function ($event) {
                                                _vm.switch_more_btn = false
                                                _vm.touchCollect()
                                              },
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "text" }, [
                                              _vm._v(
                                                _vm._s(
                                                  `${
                                                    _vm.item.collectionflag
                                                      ? "取消"
                                                      : ""
                                                  }`
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.sell
                                      ? [
                                          _vm.putaway_status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "list_group un_sel cp",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.switch_more_btn = false
                                                      _vm.doPutaway()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "text" },
                                                    [_vm._v("上架至商城")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.sold_out_status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "list_group un_sel cp",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.switch_more_btn = false
                                                      _vm.doSoldOut()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "text" },
                                                    [_vm._v("从商城下架")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "list_group un_sel cp",
                                            on: {
                                              click: function ($event) {
                                                _vm.switch_more_btn = false
                                              },
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "text" }, [
                                              _vm._v("转发任务"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.recycle
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "list_group un_sel cp no_icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.recoverTask()
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "text" }, [
                                          _vm._v("恢复"),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "list_group un_sel cp no_icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.completelyDelete()
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "text" }, [
                                          _vm._v("彻底删除"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", {
                            staticClass: "more_btn cp",
                            attrs: { slot: "reference" },
                            on: {
                              click: function ($event) {
                                return _vm.$api.stopPropagation($event)
                              },
                            },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              !_vm.recycle && _vm.show_time
                ? _c("div", { staticClass: "time_box" }, [
                    _vm._v(
                      _vm._s(_vm._f("universalDate")(_vm.item.updateTime))
                    ),
                  ])
                : _vm._e(),
              _vm.recycle
                ? _c("div", { staticClass: "time_box" }, [
                    _vm._v(
                      _vm._s(
                        _vm.item.deleteDays !== null
                          ? `剩余${_vm.item.deleteDays}天`
                          : _vm.timeRemaining(_vm.item.updateTime)
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "template_info_group",
                class: { separate: _vm.separate },
              },
              [
                _vm.item.taskType == 2 && _vm.item.draft !== 1
                  ? _c("div")
                  : _vm._e(),
                (_vm.item.taskType != 2 || _vm.item.draft === 1) &&
                _vm.item.createuserthumbnail
                  ? _c(
                      "div",
                      { staticClass: "avatar_group" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.item.createusername,
                              placement: "bottom",
                              "open-delay": 500,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${_vm.item.createuserthumbnail}@!small200`,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.item.taskchatnum && 0,
                              expression: "item.taskchatnum && 0",
                            },
                          ],
                          staticClass: "new_chat_icon",
                          attrs: {
                            src: require("@/assets/images/dialog/new_chat_icon.gif"),
                            alt: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                (!_vm.sell && _vm.show_tag) || 1
                  ? _c("div", { staticClass: "tag_box" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.item.isremind,
                            expression: "item.isremind",
                          },
                        ],
                        staticClass: "tag reminder",
                      }),
                      0
                        ? _c("div", { staticClass: "tag finance" }, [
                            _vm._v("\n          财\n        "),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.draft,
                              expression: "draft",
                            },
                          ],
                          staticClass: "tag draft",
                        },
                        [_vm._v("草")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.item.taskType == 2 ||
                                _vm.item.taskType == 3,
                              expression:
                                "item.taskType == 2 || item.taskType == 3",
                            },
                          ],
                          staticClass: "tag teach",
                        },
                        [_vm._v("教")]
                      ),
                      _vm.item.hide
                        ? _c("div", { staticClass: "tag privacy" }, [
                            _vm._v("私"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.show_teaching
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "teaching_group d-flex align-center justify-space-between",
                      },
                      [
                        _vm.show_audit
                          ? _c(
                              "div",
                              {
                                staticClass: "audit",
                                style: {
                                  color: _vm.item.getAuditStatus()
                                    ? _vm.item.getAuditStatus().color
                                    : "#999",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.getAuditStatus()
                                      ? _vm.item.getAuditStatus().audit
                                      : ""
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.show_putaway && _vm.item.showflag
                          ? _c("img", {
                              staticClass: "putaway",
                              attrs: {
                                src: require("@/assets/images/task_thumb/putaway.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.show_from && _vm.item.from
      ? _c(
          "div",
          { staticClass: "from_group cp", on: { click: _vm.openTaskBox } },
          [
            _c("div", { staticClass: "tag" }, [_vm._v("来源:")]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.item.from,
                  placement: "bottom-start",
                  "open-delay": 500,
                },
              },
              [
                _c("div", { staticClass: "from" }, [
                  _vm._v(_vm._s(_vm.item.from)),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }